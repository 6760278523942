<template>
  <div class="d1">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <input type="text" v-model="email" placeholder="Username" />
      <input type="password" v-model="password" placeholder="Password" />
      <button type="submit">Login</button>
    </form>
    <p class="p1">{{ errorMessage }}</p>
    <div class="d2">Don't have an account? <button @click="$router.push('/signup')">Sign Up!</button> </div>
    <div class="d3">
      <button @click="$router.push('/resetpassword')">Forgot Password?</button>
    </div>
  </div>  
</template>

<script>
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import store from '../store/index';
// import { auth } from '../firebaseConfig.js';
export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      const auth = getAuth();
      this.errorMessage = '';
      if(this.email === ''){
        this.errorMessage = "You must enter a valid email address";
        return;
      } else if(this.password===''){
        this.errorMessage="You must enter a valid password";
        return;
      }      
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCred) => {          
          const user = userCred.user;                    
          store.commit('setUser', { email: user.email });
          this.$router.push(this.$route.query.redirect || '/');
        })
        .catch((error) => {
          this.errorMessage = error.message.replace('Firebase: ', '');
        });
    }
  }
};
</script>

<style scoped>
.d1 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items:center;
  margin-top:20%;
}
.d2, .d3 {
  margin: 5px;
}
.p1 {
  color: red;
}
</style>