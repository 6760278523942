import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyDHGbpJk3PP357ge7izQ3JrffudOtP3ze0',
  authDomain: 'rexroth-development.firebaseapp.com',
  projectId: 'rexroth-development',
  storageBucket: 'rexroth-development.firebaseapp.com',
  messagingSenderId: '337014675023',
  appId: '1:337014675023:web:c41393fb01811e819c7c3a',
  measurementId: "G-NXY9DTQEQY"
}
// firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
