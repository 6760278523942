<template>
  <div class="parent">
    <div class="child1">Test 123<br></div>    
    <div class="child2">
      <!-- <div class="parent2"> -->
        <div class="child3">Test 123<br></div>
        <div class="child4">Test 123<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>
          Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>
          Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test 234<br>Test abc<br>Test abc<br>Test abc<br>
        </div>      
      <!-- </div> -->
    </div>    
  </div>
  <div class="parent">
    <div class="child1">Test 123<br></div>
    <div class="child2">Test 234</div>
  </div>
</template>

<style scoped>
.parent {
  display: block;  
  height: 250px;
  border: 5px solid orange;  
  display: flex;
  flex-direction: column;
}
.parent2 {
  display: flex;
  flex-flow: column;
}
.child1 {    
  height: 50px;
  background-color: green;  
}
.child2 {  
  background-color: blue;  
  display: flex;
  flex-direction: column;
  height: 100%;  
  overflow: hidden;
}
.child3 {  
  background-color: orange;
  height: 50px;
}
.child4 {  
  flex: 1 1 auto;
  background-color: grey; 
  overflow-y:auto;  
  height: 0px;
}
</style>