export default {
  getShowPP: (state) => {
    return state.showpp;
  },
  getCategory: (state) => {
    return state.category;
  },
  isAuthenticated: (state) => {
    if(localStorage.getItem('user')){
      return true;
    }
    return !!state.user;
  },
  getUser: (state) => {
    if(localStorage.getItem('user')){
      return localStorage.getItem('user');
    }
    return state.user;
  },
  getGoto: (state) => {
    return state.goto;
  },
  viewHeight: (state) => {
    return state.viewHeight;
  }
}
