<template>  
  <div class="d1">
    <div class="pTitle"><div class="pTitle1">REVIEWS</div></div>    
    <div class="d2">
      <iframe title="Rexroth Development Reviews from Google and Facebook" onload="iFrameResize(this)" class="iframe1" src='https://ec4a2f9725ff44928e3248bbc8ba303e.elf.site' frameborder='0' width="80%"></iframe>    
    </div>
  </div>
</template>


<script>
  export default {
    name: 'Reviews',
    computed: {
      viewHeight() {
        return this.$store.getters.viewHeight;
      }
    },
    mounted() {
      let resizerScript = document.createElement('script');
      resizerScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.2.10/iframeResizer.min.js');
      document.head.appendChild(resizerScript);
    }
  }
</script>
<style scoped>
.d1 {  
  position: relative;
  width: 100%;
  height:v-bind('viewHeight');  
  background-color: #595959;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
}
.d2 {  
  width: 100%;    
  /* min-height: 85vh; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  margin-top: 30px;
}

.pTitle{
  /* position: absolute;       */
  /* width: calc(100% - 16px); */
  width: 100%;
  text-align: center;
  font-size:4rem;
  color: white; 
  height:calc(v-bind('viewHeight') * .15);
  margin-top: 25px;
}
.pTitle1{
    width: 70%;
    margin-left: 15%;
    border-bottom:3px solid white;
}
@media only screen and (max-width: 900px) {
  .pTitle1 {
    width: 90%;
    margin-left: 5%;
  }
}
</style>