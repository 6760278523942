<template>
  <!-- Enlarged Photo -->  
  <div class="g2" v-if="activeIndex >= 0">
    <button class="b1" @click="closeImages">X</button>
    <button class="bl" @click="leftClick">&lt;</button>
    <button class="br" @click="rightClick">&gt;</button>
    <img class="t2" v-click-outside="closeImages" :src="images[activeIndex].src" :alt="images[activeIndex].title" />           
  </div>
  <!-- Thumbnails -->
  <div class="g1">    
    <img class="t1" style="object-fit:contain"  v-for="(image, index) in images" :key="index" :src="image.src" :alt="image.title" @click="activeIndex=index"/>            
  </div>
</template>

<script>

export default {  
  props: { images: Array},  
  data() {
    return {
      activeIndex: -1,
      btnClick: false,
    }
  },  
  watch: {
    activeIndex(){
      if(this.activeIndex>=0){
        document.body.style.overflow="hidden";
      } else {
        document.body.style.overflow="unset";
      }
    }
  },
  computed: {
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },
  methods: {
    rightClick() {      
      this.btnClick=true;
      if(this.activeIndex+1 < this.images.length){
        this.activeIndex = this.activeIndex+1;
      } else {
        this.activeIndex = 0;
      }
    },
    leftClick() {
      this.btnClick=true;
      if(this.activeIndex-1 >= 0){           
        this.activeIndex = this.activeIndex - 1;
      } else {
        this.activeIndex = this.images.length-1;        
      }
    },
    closeImages(){
      setTimeout(() => {
        if(this.btnClick!=true){
          this.activeIndex = -1;
        }
        this.btnClick=false;
      }, 10);
      
    },
    handleKeyPress(e){
      if(this.activeIndex >= 0){
        if(e.key==="ArrowRight"){
          this.rightClick();
        } else if(e.key==="ArrowLeft") {
          this.leftClick();
        } else if(e.key==="Escape") {
          this.activeIndex=-1;
        }
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyPress);            
  }
}
</script>

<style scoped>
button {
  position:absolute;
  background-color: rgb(56, 56, 56);
  color: White;
  display: flex;
  justify-content: center;
  align-items: center;
  width:30px;
  height:30px;
  border-radius: 5%;
  border-style: unset;
}
/* Thumbnails */
.t1 {
  width: 23%;
  aspect-ratio: 3/2;  
  margin:1%;    
  /* background-color: rgb(112, 115, 121); */
}
.t1:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.g1 {
  text-align:center;  
  padding: 1%;
  z-index:1;
  border-style: inset;
  border: 1.5px inset grey;
  background-color: rgb(99, 103, 111);
}

.g2 {
  position: fixed;
  width: 100vw;
  height: v-bind('viewHeight');
  top:0;
  left:0;
  background-color: rgba(56, 56, 56, 0.796);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:100;
  overflow:hidden;
}
.g3 {
  display: flex;
  justify-content: space-between;
}
.b1 {
  right:40px;
  top: 40px;
}
.bl {
  left:40px;
}
.br {
  right:40px;
}
.b1:hover, .bl:hover, .br:hover {
  background-color: grey;
  cursor: pointer;
}
.t2 {
  max-width:80vw;
  max-height:calc(v-bind('viewHeight') * .8);
}

</style>