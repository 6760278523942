<template>
    <div class="projects">
        <div class="pTitle"><div class="pTitle1">PROJECTS</div></div>
        <div class="projectRows">
            <div :class="{rowStyle: !isMobile, mRowStyle: isMobile }">
                <div class="boxStyle" :style="boxStyle" @click="projectEvent('project1')">
                    <img src="../../assets/oInnAtWindmereHome.jpg" alt="Inn At Windmere">
                    <div class="boxText">Company Website</div>
                    <div class="boxText2">(Vue.js)</div>
                </div>
                <div class="boxStyle" :style="boxStyle" @click="projectEvent('project2')">
                    <img src="../../assets/oDabstractHome.jpg" alt="Dabstract">
                    <div class="boxText">Company Website</div>
                    <div class="boxText2">(Squarespace integration with AWS)</div>
                </div>
                <div class="boxStyle" :style="boxStyle" @click="projectEvent('project3')">
                    <img src="../../assets/oRBIHome.jpg" alt="Rexroth Building &amp; Interiors">
                    <div class="boxText">Company Website</div>
                    <div class="boxText2">(Vue.js)</div>
                </div>
            </div>
        </div>
        <div class="projectRows">
            <div :class="{rowStyle: !isMobile, mRowStyle: isMobile }">
                <div class="boxStyle" :style="boxStyle" @click="projectEvent('project4')">
                    <img src="../../assets/FullPotential.jpg" alt="Full Potential Inc.">
                    <div class="boxText">Company Website</div>
                    <div class="boxText2">(Vue.js)</div>
                </div>
                <div class="boxStyle1" :style="boxStyle">
                    <img src="../../assets/WebSockets.jpg" alt="WebSockets Project">
                    <div class="boxText">Websocket Services</div>
                    <div class="boxText2">(C# .NET)</div>
                    <div class="comingSoon" id="comingSoon">Coming Soon...</div>
                </div>
                <div class="boxStyle1" :style="boxStyle">
                    <img src="../../assets/oIVR.jpg" alt="Interactive Voice Response Project">
                    <div class="boxText">IVR Systems</div>
                    <div class="boxText2">(Amazon Web Services)</div>
                    <div class="comingSoon" id="comingSoon">Coming Soon...</div>
                </div>

            </div>
        </div>
        <div class="projectRows">
            <div :class="{rowStyle: !isMobile, mRowStyle: isMobile }">
                <div class="boxStyle1" :style="boxStyle">
                    <img src="../../assets/oIVR.jpg" alt="Interactive Voice Response Project">
                    <div class="boxText">IVR Systems</div>
                    <div class="boxText2">(Twilio, Workflow)</div>
                    <div class="comingSoon" id="comingSoon">Coming Soon...</div>
                </div>
            </div>
        </div>
        <div class="projectRows"></div>
    </div>
</template>

<script>

export default {
  props: ['goto'],
  data () {
    return {
      isMobile: false
    }
  },
  methods: {
    projectEvent (project) {
      this.goto(project)
    },
    windowSize () {
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  computed: {
    boxStyle () {
      return {
        width: this.boxSize
        // height: this.boxSize,
      }
    },
    boxSize () {

      return ((window.innerWidth / 3)-30).toString() + 'px'
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
  }
}
</script>

<style scoped>
.projects {
  background-color:rgb(167, 172, 185);
  width: 100%;
  /* min-height: 100vh; */
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.projectRows {
    /* height:40%; */
    width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin-top:40px;
}
.pTitle{
    display:flex;
    justify-content: center;
    font-size:4rem;
    color:rgb(64, 64, 64);
    /* color:rgb(99, 99, 99); */
    align-items:center;
    height:15%;
    margin-top: 25px;
}
.pTitle1{
    border-bottom:3px solid rgb(64, 64, 64);
}
.rowStyle {
    display: flex;
    justify-content: space-evenly;
}
.boxStyle, .boxStyle1 {
    /* margin-top: 30vh; */
    padding:10px;
    background-color:rgb(100, 100, 100);
    /* box-shadow: 10px 10px 5px black;   */
    border: 1px solid black;
    text-align:center;
    display: flex;
    /* justify-content: center; */
    /* align-content: center; */
    flex-direction: column;
    position:relative;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}
.boxStyle:hover {
    background-color:rgb(133, 133, 133);
    cursor:pointer;
}
.boxText {
    color:white;
    font-size:1.5em;
    padding:10px;
}
.boxText2 {
    color:white;
}
.boxStyle1:hover .comingSoon {
    display:flex;
    cursor:pointer;
}
.comingSoon {
    display:none;
    color: white;
    font-size: 1.5em;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    align-items:center;
    justify-content: center;
    z-index:2;
    background-color:rgba(202, 202, 202, 0.725);
}

@media only screen and (max-width: 900px) {
    .projects {
        height:100%;
    }
    .projectRows{
      margin:0;
    }
  .pTitle {
    margin-bottom: 30px;
  }
.mRowStyle{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    /* margin-top:10px; */
}
.boxStyle, .boxStyle1 {
    margin-bottom:10px;
    width:85% !important;
}
.boxStyle img, .boxStyle1 img {
    height:auto;
    width:100%;
    min-height:none;
    object-fit: cover;
}
}
</style>
