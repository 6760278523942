import { createRouter, createWebHistory } from 'vue-router'
import store from './store/index.js';
// import {getAuth} from 'firebase/auth';

import MainView from './pages/MainView.vue';
import BlogList from './pages/BlogList.vue';
import BlogPost from './pages/BlogPost.vue';
import Login from './auth/Login.vue';
import SignUp from './auth/SignUp.vue';
import ResetPassword from './auth/ResetPassword.vue';
import Test from './components/layouts/Test.vue';

const router = createRouter({
  history: createWebHistory(),
  // routes: [
  //     { path: '/', redirect: '/home'},
  //     { path: '/home', name: 'mainview', component: MainView },
  // ],
  routes: [
    {
      path: '/',
      name: 'mainview',
      component: MainView,
      alias: ['/home', '/reviews', '/about', '/projects', '/contact']
    },
    {
      name: 'blogList',
      path: '/blog',
      component: BlogList
    },
    {
      name: 'blogPost',
      path: '/blog/:slug',
      component: BlogPost
    },
    { 
      path: '/blog/:slug/preview',
      component: BlogPost,
      props: (route) => ({preview: route.query.preview==='true'}),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/signup',
      component: SignUp
    },
    {
      path: '/resetpassword',
      component: ResetPassword
    },
    {
      path: '/test',
      component: Test
    }
  ],
  scrollBehavior (to) {
    // if(savedPosition) {    
    //     return savedPosition;
    // }
    //  START HERE
    // 1. Check if done loading
    // 2. then do window.scrollTo
    // 3. randomly scrolling to different area of page?
    if(to.query && to.query.pp && to.query.pp === 'true'){      
      setTimeout(() => {
        store.dispatch('setShowPP', { showpp: true })
      }, 100);      
    }
    if (to.query && to.query.id) {      
      if (to.params.update === null || to.params.update != 'false') {
        setTimeout(() => {
          document.title = to.query.id.charAt(0).toUpperCase() + to.query.id.slice(1) + ' | Rexroth Development'
          if (to.query.update === 'false') {
            return
          }
          if (to.query.id === 'home') {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          } else if (to.query.id === 'contact') {
          //   // var b = document.getElementById('contact').getBoundingClientRect().bottom + 8;
            const b = document.getElementById('contact').offsetTop + 8
            window.scrollTo({ top: b, behavior: 'smooth' })
          } else {
            // const b = document.getElementById(to.query.id).offsetTop + 8;
            // const b = this.$refs[to.query.id].scrollHeight;
            // window.scrollTo({ top: b, behavior: 'smooth'})
            console.log(to.query.id);
            document.getElementById(to.query.id).scrollIntoView({ behavior: 'smooth' })
          }
        }, 100)
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);    
  if(to.fullPath.includes('/logout')){
    store.commit('logOut');    
    if(to.fullPath.includes('/preview')) next({path: 'login', query:{ redirect: to.fullPath.replace('/logout', '')}});
    else next(to.fullPath.replace('/logout', ''));
  }  
  if(requiresAuth && !store.getters.isAuthenticated) next({path: 'login', query:{ redirect: to.fullPath}});
  else next();
}) 
export default router
