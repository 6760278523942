import {initializeApp, getApps } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDHGbpJk3PP357ge7izQ3JrffudOtP3ze0",
  authDomain: "rexroth-development.firebaseapp.com",
  databaseURL: "https://rexroth-development-default-rtdb.firebaseio.com",
  projectId: "rexroth-development",
  storageBucket: "rexroth-development.appspot.com",
  messagingSenderId: "337014675023",
  appId: "1:337014675023:web:c41393fb01811e819c7c3a",
  measurementId: "G-NXY9DTQEQY"
};

let firebaseApp;
if(!getApps().length){
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApps()[0];
}

const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export default db;
