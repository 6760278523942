<template>
    <div class="topSection">
      <the-header :goto="goto" activePage="home"></the-header>
      <div class="welcomePrompt">
        <div class="logo">
          <!-- <h1>Rexroth Development</h1> -->
          <!-- <h2>Generating unique front-end and back-end solutions for all types of projects</h2> -->
          <!-- <h2>High Quality Software Solutions</h2> -->
          <img src="../../assets/logo2.png" class="logoImg" alt="Rexroth Development: Quality Software Solutions"/>
        </div>
      </div>
    </div>
    <div class="middleSection">
      <div class="secondPrompt">        
        <div class="spText">Custom full stack software development: websites, mobile applications, cloud-based services, API integrations. Let's discuss your unique software need today</div>        
        <router-link name="msgUs" :to="{name: 'mainview', query: {id: 'contact'}}" class="hereLink">Message Us</router-link>
      </div>      
    </div>
</template>

<script>
export default {
  props: ['goto'],
  data () {
    return {
      isMobile: false
    }
  },
  computed: {
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },
  methods: {
    windowSize () {
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
  }
}
</script>

<style scoped>
.topSection {
  background-color:rgb(86, 105, 133);
  width: 100%;
  min-height: calc(v-bind('viewHeight')*.65);
  color:black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.middleSection {
  background-color:rgb(167, 172, 185);
  width: 100%;
  min-height: calc(v-bind('viewHeight')*.35);
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcomePrompt{
  height:calc(v-bind('viewHeight') * .59 - 13px);
  display:flex;
  text-align:center;
  align-items: center;
  justify-content: center;
  font-size:1.5em;
}
.logo {
  background-color:white;
  padding:5px;
  border-style: inset;
  border-width: 5px;
  /* height:70%;     */
  max-width: 80%;  
  height: 70%;
}
.logoImg {  
  position: relative;
  width:100%;  
  height: 100%;
  object-fit: contain;
}
.welcomePrompt h2 {
  font-style:italic;
  font-size:1.2em;
}
.secondPrompt {
  width: 50vw;
  height:100%;
  text-align:center;
  margin:auto;
  display:flex;
  align-items: center;
  font-size:1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.spTitle {
  font-size:1.3em;
}
.spText {

}
.hereLink {
  color:white;
  text-decoration: none;
  background-color: #505D78;
  padding: 15px;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
  background-clip:border-box;
  position: relative;
  border-radius: 8px;
  margin-top: 15px;  
}
.hereLink:hover {  
  /* color:rgb(71, 100, 197); */
  background-color:#727a8a;
  cursor:pointer;
}

@media only screen and (max-width: 900px) {

.logo{
  /* width:80vw;
  height:auto; */
}

.logoImg {
  /* width:80vw; */
}
.welcomePrompt{
  height:calc(v-bind('viewHeight') * .59 - v-bind('viewHeight') * .06);
}
.spText {
  font-size:.8em;
}

.topSection{
  height:calc(v-bind('viewHeight') * .6);
}
.middleSection{
  height:calc(v-bind('viewHeight') * .4);
}
.secondPrompt {
  width:82vw;
}
}
</style>
