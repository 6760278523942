import { createStore } from 'vuex'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

const store = createStore({
  state () {
    return {
      showpp: false,
      category: 'All',
      user: null,
      goto: '',
      viewHeight: 0,
    }
  },
  mutations,
  actions,
  getters
})

export default store
