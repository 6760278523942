<template>
  <metainfo></metainfo>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "name": "Rexroth Development LLC",
        "image": "https://rexrothdev.com/img/logo2.22ea8c9a.png",
        "telephone": "+1-317-610-0497",
        "email": "andrew@rexrothdev.com",
        "url": "https://rexrothdev.com",
        "address": {
          "@type":"PostalAddress","addressLocality":"Indianapolis","addressRegion":"IN","addressCountry":"US","postalCode":"46220","streetAddress":"6101 North Keystone Suite 1200c #1117"
        }
      }
    </component>
  </teleport>  
  <PrivacyPolicy class="PrivacyPolicy" v-click-outside="onClickOutside" v-if="showpp" />    
  <transition>
    <div class="theHeader" v-if="showHeader">
      <div class="whiteTop"></div>
      <the-header :activePage="activePage"></the-header>
    </div>
  </transition>   
  <div class="whiteTop"></div>
  <the-header :activePage="activePage"></the-header>
  <router-view>    
  </router-view>

  <!-- <main-view>
  </main-view> -->
</template>

<script>
import { useMeta } from 'vue-meta'
import PrivacyPolicy from './components/layouts/PrivacyPolicy.vue'
import TheHeader from './components/layouts/TheHeader.vue';
export default {
  setup () {
    useMeta({
      title: 'Rexroth Development LLC',
      htmlAttrs: { lang: 'en' },
      description: 'Rexroth Development LLC is a software development company that provides Custom full stack software development solutions. We specialize in custom development for: websites, mobile applications, cloud-based services and API integrations. This is a remote company that works with small businesses or individuals to design and develop their desired unique software applications.',
      keywords: 'remote software development services, remote programming services, freelance software developers, remote web development, remote full stack development',
      author: 'Andrew Rexroth',
      viewport: 'width=device-width, initial-scale=1.0',
      robots: 'index, follow',
      link: [
        { rel: 'canonical', href: 'https://rexrothdev.com/' },
        { rel: 'icon', type: 'image/png', href: '/rexrothDevIcon.png' },
        { rel: 'apple-touch-icon', href: '/rexrothDevIcon.png' }
      ],
      titleTemplate: '%s | Rexroth Development LLC'
    })
  },
  name: 'App',
  components: { PrivacyPolicy, TheHeader },
  data() {
    return {
      showHeader: false,
      scrollPosition: null,      
    }
  },
  created () {
  },
  methods: {
    onClickOutside () {      
      this.$store.dispatch('setShowPP', { showpp: false})
    },
    updateScroll () {
      const curPos = window.scrollY
      if (curPos < this.scrollPosition) {
        this.showHeader = true
      } else if (curPos > this.scrollPosition) {
        this.showHeader = false
      }
      this.scrollPosition = window.scrollY
    },   
  },
  computed: {
    showpp() {
      return this.$store.getters.getShowPP
    },
    activePage() {
      if(this.$route.query.id){
        return this.$route.query.id;
      } else if(this.$route.fullPath.includes('/blog')){
        return 'blog';
      } else if(this.$route.fullPath.includes('/reviews')){
        return 'reviews';
      } 
      return 'home';
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)  
    this.$store.dispatch('setViewHeight', { viewHeight: window.innerHeight + 'px'} );

    const script = document.createElement('script');
    script.src = 'https://cdn.userway.org/widget.js';
    script.setAttribute('data-account', process.env.VUE_APP_USERWAY_DATA_ACCOUNT);
    script.setAttribute('alt', 'Accessibility Menu');
    document.head.appendChild(script);
  }
}
</script>
<style>
/* * { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
     background-clip:border-box;
  } */
body{
  font-family: Helvetica, sans-serif;
  
}

/* #app {
  width: 100%;
} */


.theHeader {
  position: fixed;      
  top: 0;  
  
  /* width: calc(100vw - 16px) !important;     */
  width: calc(100% - 16px);
  border: none;
  border-top: 8px solid white;  
  box-sizing: border-box;
  z-index: 100;
  background-clip:border-box;  
  overflow: none;
}
.theHeader .menuBtn {
  right:22px !important;
}
.theHeader .blogPostBack {
  left:22px !important;
}
.v-enter-from {
  opacity: 0;
}
.v-enter-active {
  transition: all 0.5s ease-out;
}
.v-enter-to {
  opacity: 1;
}
.v-leave-from {
  opacity: 1;
}
.v-leave-active {
  transition: all 0.5s ease-in;
}
.v-leave-to {
  opacity: 0;
}
.menu-enter-from {
  transform: translateX(40vw);
}
.menu-enter-active {
  transition: all 0.5s ease-out;
}
.menu-leave-active {
  transition: all 0.5s ease-in;
}
.menu-leave-to {
  transform: translateX(40vw);
}
@media only screen and (max-width: 900px) {
.theHeader {
  width: calc(100vw - 16px);
}
}
</style>