<template>
    <spin-modal :title="title" :loading="loading" v-if="showModal" @close="closeModal"></spin-modal>
    <div class="pageView">
        <div class="pTop">
            <div class="pTop1">
                <div class="topText1">We want to hear from you!</div>
                <div class="topOptions">
                    <div class="oBlock" @click="openEmail">
                        <div class="oTitle">Send us an email</div>
                        <!-- <span class="material-symbols-rounded">
                        mail
                        </span> -->
                        <img class="img1" src="../../assets/emailIcon.png" alt="Email Icon" />
                        <div class="oText">andrew@rexrothdev.com</div>
                    </div>
                    <div class="oBlock" @click="callPhone">
                        <div class="oTitle">Give us a call or text</div>
                        <!-- <span class="material-symbols-rounded">
                            call
                        </span> -->
                        <img class="img1" src="../../assets/phoneIcon.png" alt="Phone Icon"/>
                        <div class="oText">+1-317-610-0497</div>
                    </div>
                    <div class="oBlock">
                      <div class="oTitle">Connect with us on the web!</div>
                      <a href="https://www.facebook.com/rexrothdev" target="_blank">
                        <img class="img1" src="../../assets/facebookIcon.png" alt="Facebook Icon"/>
                      </a>
                      <a href="https://www.instagram.com/rexrothdev/" target="_blank">
                        <img class="img1" src="../../assets/instagramIcon.png" alt="Instagram Icon" />
                      </a>
                      <a href="https://www.linkedin.com/in/andrewrexroth/" target="_blank">
                        <img class="img1" src="../../assets/linkedinIcon.png" alt="LinkedIn Icon"/>
                      </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="pBottom">
            <div class="pBottom1">
                <div class="bLeft">
                    <div class="bLeft1">
                        <div class="bText1">Message Us</div>
                        <div class="bText2">Please provide a detailed message and we will promptly get back with you. Thanks!</div>
                    </div>
                </div>
                <div class="bRight">
                    <div class="contactForm">
                        <form class="contactForm1" @submit.prevent="sendMessage">
                            <label for="firstname">First Name:</label>
                            <input v-model="firstname" id="firstname" name="firstname"/>
                            <div v-if="warnings[0]" class="warningMsg">Please enter your first name</div>
                            <div v-else class="warningMsg"></div>
                            <label for="lastname">Last Name:</label>
                            <input v-model="lastname" id="lastname" name="lastname"/>
                            <div v-if="warnings[1]" class="warningMsg">Please enter your last name</div>
                            <div v-else class="warningMsg"></div>
                            <label for="email">Email:</label>
                            <input v-model="email" id="email" type="email" name="email"/>
                            <div v-if="warnings[2]" class="warningMsg">Please enter your email address</div>
                            <div v-else class="warningMsg"></div>
                            <label for="message">Message:</label>
                            <textarea v-model="message" id="message" class="messageBlock" name="message" />
                            <div v-if="warnings[3]" class="warningMsg">Please enter a message</div>
                            <div v-else class="warningMsg"></div>
                            <button  type="submit" value="Send" >SEND!</button>
                            <div v-if="emailStatus==='success'" :class="['emailStatus','emailSuccess']">Your message has been sent, Thanks!</div>
                            <div v-else-if="emailStatus==='falure'" :class="['emailStatus','emailFailure']">Sorry, your message failed to send</div>
                            <div v-else class="emailStatus"></div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emailjs from 'emailjs-com'
import SpinModal from './SpinModal.vue'
import { collection, addDoc } from 'firebase/firestore'
import { signInAnonymously } from 'firebase/auth'
import { db, auth } from '../../firebase/init.js'

// import {db, } from '../../firebase/init.js';
export default {
  components: { SpinModal },
  data () {
    return {
      warnings: [],
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      emailStatus: '',
      title: 'Sending Message...',
      loading: true,
      showModal: false,
      isMobile: false
    }
  },
  methods: {
    async createMessage () {
      const contactsCol = collection(db, 'contacts')
      const dataObj = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        message: this.message
      }
      try {
        let signedIn = false
        await signInAnonymously(auth).then(() => {          
          signedIn = true
        })
          .catch((error) => {
            console.log('Error signing in: ' + error.message + 'code: ' + error.code)
          })
        if (signedIn === true) {
          const docRef = await addDoc(contactsCol, dataObj)
          console.log('Document was created with ID: ', docRef.id)          
        }
      } catch (error) {
        console.log('Error adding message to database: ' + error)
      }
    },
    timeout (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    openEmail () {
      document.location = 'mailto:andrew@rexrothdev.com'
    },
    callPhone () {
      if (this.isMobile) {
        document.location = 'tel:+13176100497'
      }
    },
    closeModal () {
      this.showModal = false
    },
    windowSize () {
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    async sendMessage (e) {
      this.emailStatus = ''
      let send = true

      this.warnings[0] = this.firstname === ''
      this.warnings[1] = this.lastname === ''
      this.warnings[2] = this.email === ''
      this.warnings[3] = this.message === ''
      for (let i = 0; i < 4; i++) {
        if (this.warnings[i] === true) {
          send = false
        }
      }
      if (send === true) {
        this.loading = true
        this.title = 'Sending Message...'
        this.showModal = true
        try {
          await emailjs.sendForm('service_q8xg2ta', 'template_2pqjelg', e.target, '3HUgnihPV6xFRGPvI').then((resp) => {
            this.loading = false
            this.createMessage()
            if (resp) {
              this.emailStatus = 'success'
              this.title = 'Your message has been sent!'
              this.firstname = ''
              this.lastname = ''
              this.email = ''
              this.message = ''
            } else {
              this.emailStatus = 'failure'
              this.title = 'Sorry, your message failed to send'
            }
          })
          await this.timeout(2000)
        } catch (error) {
          this.error = error
        }
        this.showModal = false
      }
    }
  },
  computed: {
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },
  created () {
    for (let i = 0; i < 4; i++) {
      this.warnings.push(false)
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

.img1 {
  width: 3.5vw;
  height: 3.5vw;
  margin: 10px;
}
.img1:hover {
  transform: scale(1.2);
}
.pageView {
  background-color:rgb(86, 105, 133);
  width: 100%;
  height: v-bind('viewHeight');;
}
.pTop {
    width:100%;
    height:calc(v-bind('viewHeight') * .35);
    padding-top:calc(v-bind('viewHeight') * .05);
    /* background-color:green;     */
}
.pTop1 {
    width:80%;
    margin-left:10%;
    height:calc(v-bind('viewHeight')*.30);
    border:5px solid white;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content: space-evenly;
}
.topText1 {
    text-align:center;
    font-size:2.3em;
}
.topOptions {
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    text-align:center;
}
.pBottom {
    width:100%;
    height:calc(v-bind('viewHeight')*.6);
    /* background-color:red; */
}
.oTitle {
    font-size:1.2em;
    color: rgb(255, 255, 255);
}
.oText {
    color:white;
    text-decoration: underline;
}
.material-symbols-rounded {
    background-color:rgb(105, 117, 138);
    border-radius:50%;
    padding:20px;
    margin:10px;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.oBlock {
    padding:10px;
}
/* .oBlock a {
  padding: 10px;
} */
.oBlock:hover {
    background-color:rgb(143, 165, 199);
    cursor:pointer;
    border-radius:12px;
}

.pBottom1 {
    width:80%;
    margin-left:10%;
    height:calc(v-bind('viewHeight')*.55);
    background-color: white;
    box-sizing:border-box;
    display:flex;
}
.bLeft {
    width:50%;
    height:80%;
    border-right:1px solid black;
    margin-top:5%;
    box-sizing: border-box;
    /* margin-bottom:5%;  */
    /* background-color:green; */
}
.bLeft1 {
    margin-left:10%;
    margin-right:8%;
}
.bText1 {
    font-size:2em;
    margin-bottom:10%;
}
.bText2 {
    font-size:1em;
}
.bRight {
    width:50%;
    height:100%;
}
.contactForm {
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-family: Helvetica, sans-serif;
}
.contactForm1 {
    width:80%;
    height:80%;
    display: flex;

    flex-direction: column;

}
.contactForm1 input {
    width:100%;
    height:1.5em;
}
.contactForm1 button {
    width:80%;
    height:2em;
    margin-left:10%;
    color:white;
    font-size:1.5em;

    background-color:rgb(86, 105, 133);
}
.contactForm1 button:hover {
  cursor: pointer;
}
.contactForm1 textarea {
    width:100%;
    padding:0;
    margin:0;
    font-family: Helvetica, sans-serif;
}
.warningMsg {
    color:red;
    font-size:.7em;
    height:2em;
}

.messageBlock {
    height:100px;
}
.emailStatus {
    height:25px;
    width:100%;
    /* background-color:green; */
    margin-top:5px;
    text-align:center;
}
.emailSuccess {
    color:green;
}
.emailFailure {
    color:red;
}

@media only screen and (max-width: 900px) {
  .img1 {
    width: 15vw;
    height: 15vw;
  }
.pageView{
    height:100%;
}
.pTop,.pTop1 {
    height:100%;
}
.pTop1{
    width:90%;
    margin:5%;
}
.pBottom,.pBottom1 {
    height:100%;
}
.pBottom1 {
    width:90%;
    margin:5%;
    padding:5%;
    flex-direction: column;
}
.pageView{
    padding-bottom:5%;
}

.bLeft{
    width:100%;
    border:none;
    text-align: center;
}
.bRight{
    width:100%;
}
.bLeft1,.bRight1{
    margin:0;
    padding:0;
}
.bRight{
    margin-top:10%;
}
.contactForm1{
    width:100%;
    height:auto;
}
.oBlock{
    background-color:rgb(143, 165, 199);
    cursor:pointer;
    border-radius:12px;
    margin:5%;
}
.topOptions{
    flex-direction: column;
}

.oText .gv-tel-link{
    display:none !important;
}
input{
    padding:0;
    padding-top:5px;
    padding-bottom:5px;
}
.oText{
  color: black;
}
.oTitle{
  color:black;
}
}
</style>
