<template>  
  <div class="background">
    <nav role="navigation">
      <transition name="menu">
        <div v-if="isMobile && showMenu" class="m1" v-click-outside="onClickOutside">
          <router-link :to="{name: 'mainview', query: {id: 'home' }}" @click="showMenu=false; goto('home')"  :class="activePage==='home' ? 'underlineText' : 'removeUnderline'" >Home</router-link>   
          <router-link :to="{name: 'mainview', query: {id: 'reviews' }}" @click="showMenu=false" :class="activePage==='reviews' ? 'underlineText' : 'removeUnderline'">Reviews</router-link> 
          <router-link :to="{name: 'mainview', query: {id: 'about' }}" @click="showMenu=false" :class="activePage==='about' ? 'underlineText' : 'removeUnderline'" >About</router-link> 
          <router-link :to="{name: 'mainview', query: {id: 'projects' }}" @click="showMenu=false" :class="activePage==='projects' ? 'underlineText' : 'removeUnderline'" >Projects</router-link>        
          <router-link :to="{name: 'mainview', query: {id: 'contact'}}" :class="[activePage==='contact' ? 'underlineText' : 'removeUnderline', 'contactUs']">Contact Us</router-link>
          <router-link :to="{name: 'blogList' }" @click="showMenu=false" :class="activePage==='blog' ? 'underlineText' : 'removeUnderline'">Blog</router-link>      
          <div :class="[ 'navBtn', 'pp']" @click="setShowPP">Privacy Policy</div>
        </div>
      </transition>        
      <div class="headerDivs">
        <div class="topLeft">
          <div v-if="!isMobile && $route.name==='blogPost'" class="blogPostBack" @click="$router.push('/blog')" >&lt; back</div>    
        </div>
        <div v-if="!isMobile" class="topMiddle">            
          <router-link :to="{name: 'mainview', query: {id: 'home' }}" class="navBtn" :class="activePage==='home' ? 'underlineText' : 'removeUnderline'" >Home</router-link>    
          <router-link :to="{name: 'mainview', query: {id: 'reviews' }}" class="navBtn" :class="activePage==='reviews' ? 'underlineText' : 'removeUnderline'" >Reviews</router-link>          
          <router-link :to="{name: 'mainview', query: {id: 'about' }}" class="navBtn" :class="activePage==='about' ? 'underlineText' : 'removeUnderline'" >About</router-link> 
          <router-link :to="{name: 'mainview', query: {id: 'projects' }}" class="navBtn" :class="activePage==='projects' ? 'underlineText' : 'removeUnderline'" >Projects</router-link>
          <router-link :to="{name: 'blogList'}" class="navBtn" :class="activePage==='blog' ? 'underlineText' : 'removeUnderline'">Blog</router-link>
          <div :class="[ 'navBtn', 'pp']" @click="setShowPP">Privacy Policy</div>        
        </div>      
        <div v-if="isMobile" class="topMiddleMobile">
          <div v-if="$route.name==='blogPost'" class="blogPostBack" @click="$router.push('/blog')" >&lt; back</div>
          <div class="title1">{{ activePage }}</div>        
          <div class="menuBtn" @click="showMenu=true">Menu</div>
        </div>                  
        <div v-if="!isMobile" class="topRight"><router-link name="contactUs" :to="{name: 'mainview', query: {id: 'contact'}}" :class="[activePage==='contact' ? 'underlineText' : 'removeUnderline', 'contactUs']">Contact Us</router-link></div>                  
      </div>    
      <div class="navLine"></div>
    </nav>
  </div>  
</template>

<script>
export default {
  // props: ['goto', 'activePage'],
  props: ['activePage'],
  // emits: ['goto'],
  data () {
    return {
      isMobile: false,
      showMenu: false,
    }
  },  
  computed: {
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },
  methods: {
    windowSize () {
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    setShowPP () {
      this.showMenu=false;
      this.$store.dispatch('setShowPP', { showpp: true })
    },
    onClickOutside() {
      this.showMenu=false;
    },
    goto(name){
      this.$store.dispatch('setGoto', { goto: name})
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
  }
}
</script>

<style scoped>
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
  }
a:-webkit-any-link {
  text-decoration: none;
}
.blogPostBack {
  width:fit-content;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box;
}
.blogPostBack:hover {
  cursor: pointer;
}
.underlineText {
  border-bottom: 1px solid white;
}
.removeUnderline {
  border-bottom: none;
}
.navLine {
  
  background-color:white;
  height:5px;
  border-color:white;
  background-clip: content-box;
}

.headerDivs {
  display:flex;
  justify-content: center;
  align-items:center;;
  background-color:rgb(100, 100, 100);
  min-height:calc(v-bind('viewHeight') * .06);
  /* width:100%; */
  /* background-color:gold; */
}
.topLeft {
  color:white;
  font-size: 1rem;
  font-family: Helvetica, sans-serif;
  transition-duration: 0.1s;
  width:20%;
  /* margin-top:.05em; */
  margin-left: 3rem;  
}
.topMiddle {
  /* background-color:orange; */

  font-size:1.1em;
  width: 60%;
  margin:auto;
  text-align:center;
  display: flex;
  justify-content: space-between;
}
.topMiddle a {
  /* background-color:red;   */
  font-family: Helvetica, sans-serif;
  color:rgb(255, 255, 255);
  margin-bottom:0px;
  padding-bottom:0px;
}
.topMiddleMobile {
  font-family: Helvetica, sans-serif;
  color:rgb(255, 255, 255);
  /* margin-bottom:0px;
  padding-bottom:0px; */
  margin: auto;
  text-transform: capitalize;  
  width: 40vw;
  display: flex;
  justify-content: center;
}
.menuBtn {  
  position:absolute;
  right:30px;
  border: 1px solid white;
  padding:5px;
  box-sizing:border-box;
  border-radius: 5px;
  translate: 5px -5px;
  background-clip:border-box;    
  margin: 9px 0;
}
/* .blogPostBack {
  position: absolute;
  left: 30px;
  border: 1px solid white;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  translate: -5px -5px;
  background-clip: border-box;
} */
.pp {
  font-family: Helvetica, sans-serif;
  color:rgb(255, 255, 255);
  margin-bottom:0px;
  padding-bottom:0px;
}
.navBtn:hover{
  color:rgb(207, 207, 207);
  cursor: pointer;
  border-bottom:1px solid rgb(207, 207, 207);
}
.topRight {
  color:white;
  font-size: 1.5rem;
  font-family: Helvetica, sans-serif;
  transition-duration: 0.1s;
  margin-right: 3rem;
  width:20%;
  text-align:right;
}
.contactUs {
  display:inline-block;
  color:white;
}
.contactUs:hover {
  cursor:pointer;
  display:inline-block;
  color:rgb(207, 207, 207);
  border-bottom:1px solid rgb(207, 207, 207);
}
.home {
  border-bottom:1px solid white;
}

.m1 {
  position: fixed;
  width: 40%;
  height:v-bind('viewHeight');
  right:0;
  background-color:rgb(100, 100, 100);
  z-index:100;
  border-left: 3px outset grey;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.m1 * {
  color:white;
  padding: 10px;
  border-bottom: .5px solid grey;  
}

.m1 :first-child{
  border-top: .5px solid grey;
  margin-top: 20px;
}

@media only screen and (max-width: 900px) {

.blogPostBack {
  position: absolute;
  left: 30px;
  border: 1px solid white;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  translate: -5px -5px;
  background-clip: border-box;
  margin: 9px 0;
}
.title1 {
  margin: 10px 0;
}
.topMiddle{
  width:100%;
  display:flex;
  justify-content: space-evenly;
}
.topLeft, .topRight {
  margin:0;
  width:0;
}

.underlineText {
  background-color: grey;
}
.removeUnderline{
  background-color: unset;
}
.menu-enter-from {
  transform: translateX(40vw);
}
.menu-enter-active {
  transition: all 0.5s ease-out;
}
.menu-leave-active {
  transition: all 0.5s ease-in;
}
.menu-leave-to {
  transform: translateX(40vw);
}

}
</style>
