<template>
  <!-- <transition>
    <div class="theHeader" v-if="showHeader">
      <div class="whiteTop"></div>
      <the-header :activePage="activePage" :menu="showMenu"></the-header>
    </div>
  </transition>   -->
  <div class="d1">
    <!-- <div class="whiteTop"></div>
    <the-header :activePage="activePage"></the-header> -->
    <div class="space1"></div>
    <h1 class="pTitle1">Rexroth Development Blog</h1>
    <h2>Exploring the latest in web development and technology</h2>    
    <div class="l1"></div>
    <div class="d8" v-if="!isMobile">
      <div class="d9" v-for="(cat, index) in categories" :key="index" :style="cat === category ? { borderBottom: '1px solid #004f95' } : ''" @click="setCategory(cat)">{{ cat }}</div>      
    </div>        
    <div class="dd1" v-if="isMobile">      
      <div class="dd2">
        <div class="dd3">Filter: </div>
        <div class="dd4"> 
          <div class="ddBtn" @click="showDD=true">{{ category }}</div>
          <div class="dd5" v-click-outside="hideDD" v-if="showDD" @click="hideDD"> 
            <div class="dd6" v-for="(cat, index) in categories" :key="index" @click="setCategory(cat)">{{ cat }}</div> <!-- dd3 -->
          </div>      
        </div>
      </div>      
    </div>        
    <div v-if="!isMobile">
      <div class="d2" v-for="index in Math.ceil(posts.length/2)" :key="index">
        <div class="d3">
          <div class="d4" @click="router.push(`/blog/${posts[2*(index-1)].fields.slug}`)">
            <div class="d6">
              <img :src="`https:${posts[2*(index-1)].fields.featuredImage.fields.file.url}`" :alt="posts[2*(index-1)].fields.featuredImage.fields.title">
              <div class="d7">
                <h3>{{ posts[2*(index-1)].fields.title }}</h3>              
                <p>{{posts[2*(index-1)].fields.subtitle}}</p>              
                <div style="height: 15px;"></div>
                <h4>{{`${posts[2*(index-1)].fields.category ? posts[2*(index-1)].fields.category + ' ~ ' : ''} ${posts[2*(index-1)].fields.author ? posts[2*(index-1)].fields.author + ' ~ ' : ''} ${(new Date(posts[2*(index-1)].sys.updatedAt)).toLocaleDateString('en-US')}`}}</h4>
              </div>
            </div>
          </div>
          <div class="d4" v-if="posts && posts.length-1>=(2*(index-1))+1" @click="router.push(`/blog/${posts[(2*(index-1))+1].fields.slug}`)">          
            <div class="d6">
              <img :src="`https:${posts[(2*(index-1))+1].fields.featuredImage.fields.file.url}`" :alt="posts[(2*(index-1))+1].fields.featuredImage.fields.title">
              <div class="d7">
                <h3>{{ posts[(2*(index-1))+1].fields.title }}</h3>              
                <p>{{posts[(2*(index-1))+1].fields.subtitle}}</p>              
                <div style="height: 15px;"></div>
                <h4>{{`${posts[(2*(index-1))+1].fields.category ? posts[(2*(index-1))+1].fields.category + ' ~ ' : ''} ${posts[(2*(index-1))+1].fields.author ? posts[(2*(index-1))+1].fields.author + ' ~ ' : ''} ${(new Date(posts[(2*(index-1))+1].sys.updatedAt)).toLocaleDateString('en-US')}`}}</h4>
              </div>
            </div>
          </div>
          <div class="d5" v-if="posts && posts.length-1<(2*(index-1))+1">        
          </div>
        </div>
      </div>      
    </div>
    <div v-else class="d11">
      <div class="d2" v-for="(post, index) in posts" :key="index">
        <div class="d3">
          <div class="d4" @click="router.push(`/blog/${post.fields.slug}`)">
            <div class="d6">
              <img :src="`https:${post.fields.featuredImage.fields.file.url}`" :alt="post.fields.featuredImage.fields.title">
              <div class="d7">
                <h3>{{ post.fields.title }}</h3>              
                <p>{{post.fields.subtitle}}</p>              
                <div style="height: 15px;"></div>
                <h4>{{`${post.fields.category ? post.fields.category + ' ~ ' : ''} ${post.fields.author ? post.fields.author + ' ~ ' : ''} ${(new Date(post.sys.updatedAt)).toLocaleDateString('en-US')}`}}</h4>
              </div>
            </div>
          </div>          
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
import contentful from '../contentful';
// import TheHeader from '../components/layouts/TheHeader.vue'
import { useRouter } from 'vue-router';

export default {
  // components: { TheHeader },
  data() {
    return {      
      allPosts: [],    
      showHeader: false,
      scrollPosition: null,
      activePage: 'blog',
      router: useRouter(),
      categories: [],
      showMenu: false,
      isMobile: false,
      showDD: false,
    };
  },
  computed: {
    posts() {
      if(this.allPosts && this.category!='All'){
        const postList = [];
        this.allPosts.forEach((post) => {
          if(post.fields.category===this.category){
            postList.push(post);
          }
        })
        return postList;
      } else {
        return this.allPosts;
      }
    },
    category() {
      return this.$store.getters.getCategory;
    },
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },
  methods: {
    updateScroll () {
      const curPos = window.scrollY
      if (curPos < this.scrollPosition) {
        this.showHeader = true
      } else if (curPos > this.scrollPosition) {
        this.showHeader = false
      }
      this.scrollPosition = window.scrollY
    },
    setCategory(name) {
      this.$store.dispatch('setCategory', { category: name})
    },
    windowSize () {      
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    // ddChange() {
    //   this.showDD=false;
    //   this.setCategory(this.cat);
    // },
    hideDD() {
      this.showDD=false;
    }
  },
  created() {    
    contentful.getEntries({ content_type: 'blogPost'}).then((response) => {
      // this.posts = response.items;   
      this.allPosts = response.items;   
      this.categories.push('All');
      this.allPosts.forEach((post) => {        
        if(post.fields.category && !this.categories.includes(post.fields.category)){
          this.categories.push(post.fields.category);
        }        
      }) 
    }).catch(console.error);    
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    window.scrollTo({
      top:0,
      left:0,
      behavior: 'smooth'
    });
    window.addEventListener('resize', this.windowSize)
    this.windowSize()
  }
};
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap'); */
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
     background-clip:border-box;
  }
h1 {
  margin: 0;
  display: flex;
  justify-content: center;
  /* font-family: Helvetica, sans-serif;
  font-weight:400; */
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size:4em;
}
h2 {
  margin: 15px 150px;  
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  /* font-family: Helvetica, sans-serif;
  font-weight:300; */
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-style:italic;
  font-size: 2em;
  color: rgb(49, 58, 66);
}
h3 {  
  font-family: Helvetica, sans-serif;
  font-weight:400;
  font-size:1.7em;
  margin: 0;
  margin-bottom: 3px;
  color: rgb(55, 63, 75);
  /* border-bottom: 2px solid black;
  box-sizing: border-box; */
}
h4 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight:200;
  font-style: italic;
  font-size: .8em;
  margin:0;
  color: rgb(88, 88, 88);  
  border-top: 1px solid rgb(194, 194, 194);
  
  padding-top:5px;
}
img {
  aspect-ratio: 3/2;
  object-fit: cover;
}
p {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight:200;
  margin:0;
  /* font-size: 1.1em; */
  color: rgb(88, 88, 88);
}
.d1 {
  /* background-color:rgb(86, 105, 133); */
  background-color: rgb(193, 195, 202);
  /* width: 100%; */
  min-height: v-bind('viewHeight');  
  /* font-family: "IBM Plex Sans", sans-serif; */
  /* font-weight: 200; */
}
/* .d11 {
  height: 100%;
} */
.d3 {
  width:100%;
  display:flex;
  justify-content: space-evenly;
}
.d4 {
  background-color: rgb(228, 232, 237);
  /* background-color: rgb(175, 181, 188); */
}
.d4, .d5 {
  width:40%;
  /* padding: 15px; */
  margin-bottom: 50px;
}

.d4:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.d6 {
  display: flex;
  flex-direction: column;
}

.d7 {
  /* background-color: rgb(255, 255, 255); */
  padding:10px 15px;

}
.d8 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.d9 {
  margin: 0 20px 40px 20px;
  color:#004f95;
  font-size:1.3em;  
  padding: 0 2px;
}
.d9:hover {
  color:rgb(65, 128, 183);
  cursor:pointer;
}
.d10 {
  display: inline-block;
  position: relative;
}
.space1 {
  height:30px;
}
/* .theHeader {
  position: fixed;
  top: 0;
  width: calc(100% - 16px);
  border-top: 8px solid white;
  box-sizing: border-box;
  z-index: 100;
} */
/* .headerDivs {
  width:calc(100% - 16px);
} */
.l1 {
  border-top: 2px solid black;
  box-sizing: border-box;
  margin:0;
  width:80%;
  margin-left:10%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
  * { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
  }
  h1 {
    font-size:3em;
    align-items:center;
    text-align:center;
  }
  h2 {
    margin: 10px;
    margin-top:15px;
    font-size: 1.5em;
    text-align: center;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: .65em;
  }
  p {
    font-size: .9em;
  }
  .d1 {
    padding-bottom: 70px;
  }
  .d2 {
    transform: translateY(70px);
  }
  .d4 {
    width: 90%;
  }
  .dd1 {
    position: absolute;
    width: calc(100% - 16px);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .dd2 {
    display: flex;
    justify-content: center;    }
  .dd3 {
    margin-right:10px;
  }
  .dd3 {
    height: 45px;    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
  } 
  .dd4 {
    width:50%;
    display: flex;
    flex-direction: column;
  }
  .dd5 {
    position: relative;
    background-color: grey;
    z-index: 100;
    margin-left:5px;
    margin-right: 5px;
    border-bottom: 2px outset rgb(161, 161, 161);
    border-right: 2px outset rgb(161, 161, 161);
    border-left: 2px outset rgb(161, 161, 161);
  }
  .dd6 {
    /* height: 40px; */
    width:100%;
    border-bottom: 1px solid rgb(105, 105, 105);
    display: flex;
    align-items:center;
    padding: 10px;
  }
  .ddBtn {    
    background-color: #E1E4EA;
    border-radius: 5px;    
    height: 45px;    
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left:10px;
    border:1px solid grey;
  }

  .l1 {
    margin-bottom: 30px;
    width: 90%;
    margin-left:5%;    
  }

}
</style>
