<template>
  <div class="d1">
    <h2>Sign Up!</h2>
    <p>Enter your desired username and password!</p>
    <form @submit.prevent="signup">
      <input type="email" v-model="email" placeholder="Email" />
      <input type="password" v-model="password" placeholder="Password" />
      <button type="submit">Sign Up</button>    
    </form>
    <p class="p1">{{errorMessage}}</p>
    <div class="d2">Already have an account: <button @click="$router.push('/login');">Login</button></div>
  </div>
  
</template>

<script>
import db from "../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import store from '../store/index';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
    }
  },
  methods: {
    async validUser(email) {
      const docRef = doc(db, "whitelistedEmails", email);
      const docSnap = await getDoc(docRef);
      return docSnap.exists();      
    },
    async signup() {
      this.errorMessage = '';
      if(this.email === ''){
        this.errorMessage = "You must enter a valid email address";
        return;
      } else if(this.password===''){
        this.errorMessage="You must enter a valid password";
        return;
      }
      else if(!await this.validUser(this.email)){
        this.errorMessage="This email is not authorized, please reach out to Rexroth Development LLC.";
        return;
      }      
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userCred) => {
          const user = userCred.user;
          store.dispatch('setUser', { email: user.email })
        })
        .catch((error) => {
          this.errorMessage = error.message.replace('Firebase: ', '');
        });
    },
  },
};
</script>

<style scoped>
.d1 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items:center;
  margin-top:20%;
}
.p1 {
  color: red;
}
</style>