<template>
  <!-- <transition>
    <div class="theHeader" v-if="showHeader">
      <div class="whiteTop"></div>
      <the-header :activePage="activePage"></the-header>
    </div>
  </transition> -->
  <div class="mainPage" ref="home" id="home">
    <!-- <div class="whiteTop" ></div> -->
    <!-- <the-header :activePage="activePage"></the-header> -->
    <div
      @click="goto('home')"
      class="returnButton"
      :class="returnButtonClass"
      v-if="showReturnToTopButton"
    >
      back to top
    </div>    
    <div ref="home">
      <main>
        <home-view :goto="goto"></home-view>
      </main>
    </div>
    <div ref="reviews" id="reviews">
      <reviews></reviews>
    </div>
    <div ref="about" id="about">
      <about-view></about-view>
    </div>
    <div ref="projects" id="projects">
      <projects-view :goto="goto"></projects-view>
    </div>
    <!-- ********** Inn At Windmere ********** -->
    <div ref="project1">
      <project-view
        projectName="Inn At Windmere"
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[0] = 'desc'"
              :class="[
                { navSelected: navActive[0] === 'desc' },
                { navUnderlined: navActive[0] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[0] = 'details'"
              :class="[
                { navSelected: navActive[0] === 'details' },
                { navUnderlined: navActive[0] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[0] = 'view'"
              :class="[
                { navSelected: navActive[0] === 'view' },
                { navUnderlined: navActive[0] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[0] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To develop a business website for The Inn at Windmere, a charming bed and breakfast, providing a seamless online experience for guests, from browsing accommodations to booking stays.<br><br>
                  You can find the full website here: <a href="https://innatwindmere.com" target="_blank">Inn At Windmere</a>
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>Create a modern, visually appealing website that reflects the inn's charm and hospitality.</li>
                    <li>Integrate an easy-to-use booking system for room reservations.</li>
                    <li>Ensure the website is mobile-friendly and responsive on all devices.</li>
                    <li>Improve SEO to increase visibility in search engine results.</li>
                    <li>Optimize load times for faster user experience.</li>
                    <li>Showcase high-quality images of the inn's rooms, amenities, and surroundings.</li>
                    <li>Provide a form where guests and message the inn with questions or comments.</li>
                  </ul>
                </div>

                <!-- <p>
                  This project was to take an existing squarespace website and come up with a method of integrating more complex functionality within it. <br><br>
                  The goal was to update the website to display current products available at specific locations, and to allow the user to search for the closest location of a specific product.
                  If possible, the site was to be unchanged otherwise and it needed to be done in an effecient mannor.
                </p> -->
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Modern, Responsive Design</div>
<ul>
  <li>Created a user-friendly and visually appealing website accessible across all devices.</li>
</ul>

<div class="pListTitle">Online Booking System</div>
                <ul>
                  <li>Implemented a seamless, easy-to-use booking interface for guests.</li>
                </ul>

                <div class="pListTitle">Optimized for SEO</div>
                <ul>
                  <li>Improved search engine visibility, enhancing discoverability.</li>
                </ul>

                <div class="pListTitle">Fast Load Times</div>
                <ul>
                  <li>Ensured efficient performance with optimized page loading.</li>
                </ul>

                <div class="pListTitle">User-friendly Navigation</div>
                <ul>
                  <li>Simplified website structure for intuitive browsing.</li>
                </ul>

                <div class="pListTitle">Visual Appeal</div>
                <ul>
                  <li>Incorporated elegant, high-quality imagery to reflect the inn's charm.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[0] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  <div class="pListTitle">Firebase</div>
                  <ul>
                    <li>Used Firebase for hosting, ensuring reliable and scalable infrastructure.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Online Booking Integration</div>
                  <ul>
                    <li>Integrated a booking system for room reservations, allowing guests to book stays directly through the website.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Responsive Design</div>
                  <ul>
                    <li>Developed a mobile-friendly design, ensuring accessibility on all devices for a wide range of users.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">SEO Optimization</div>
                  <ul>
                    <li>Enhanced SEO strategies to increase visibility in search results and attract more potential guests.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Image Gallery</div>
                  <ul>
                    <li>Incorporated a gallery to display high-quality images of rooms, amenities, and scenic surroundings.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Optimized Performance</div>
                  <ul>
                    <li>Improved website load times by optimizing images and content, ensuring a smooth user experience.</li>
                  </ul>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>Firebase</li>
                    <li>Vue.js</li>
                    <li>JavaScript</li>
                    <li>HTML/CSS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>
            <iframe
              src="https://innatwindmere.com"
              width="100%"
              height="100%"
              frameborder="0"
              title="The Inn At Windmere - Example"
            ></iframe>
          </div>
        </div>
      </project-view>
    </div>
    <!-- ********** Dabstract Drops ********** -->
    <div style="height: 100%" ref="project2">
      <project-view
        projectName="Dabstract Drops"
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[1] = 'desc'"
              :class="[
                { navSelected: navActive[1] === 'desc' },
                { navUnderlined: navActive[1] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[1] = 'details'"
              :class="[
                { navSelected: navActive[1] === 'details' },
                { navUnderlined: navActive[1] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[1] = 'view'"
              :class="[
                { navSelected: navActive[1] === 'view' },
                { navUnderlined: navActive[1] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[1] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To enhance an existing Squarespace website for Dabstract Drops by adding complex functionality beyond the capabilities of standard Squarespace templates. The goal was to load dynamic product data weekly, display delivery schedules and product availability by store, and allow users to filter products and find the nearest store carrying their desired product.
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>Allow users to filter products by City, Store, Strain, Product Type, or Zip Code.</li>
                    <li>Update filtered results dynamically after each user selection.</li>
                    <li>Display up to 25 nearest stores based on the user’s location or entered zip code that match the filter criteria.</li>
                    <li>Implement an efficient and responsive design across devices.</li>
                    <li>Ensure low-cost operation and integration of new features.</li>
                    <li>Display store info and available products at each store.</li>
                  </ul>
                </div>

                <!-- <p>
                  This project was to take an existing squarespace website and come up with a method of integrating more complex functionality within it. <br><br>
                  The goal was to update the website to display current products available at specific locations, and to allow the user to search for the closest location of a specific product.
                  If possible, the site was to be unchanged otherwise and it needed to be done in an effecient mannor.
                </p> -->
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Low-Cost Integration</div>
                <ul>
                  <li>Utilized free-tier services from AWS and Google APIs, keeping costs minimal for the added functionality.</li>
                </ul>
                <br>

                <div class="pListTitle">Dynamic Data Loading</div>
                <ul>
                  <li>Implemented weekly automatic updates of product and store data, ensuring accuracy without manual intervention.</li>
                </ul>
                <br>

                <div class="pListTitle">Advanced Filtering System</div>
                <ul>
                  <li>Allowed users to easily filter products and find stores matching their preferences, improving user experience and engagement.</li>
                </ul>
                <br>

                <div class="pListTitle">Proximity-based Store Results</div>
                <ul>
                  <li>Displayed the 25 nearest stores based on user location, enhancing the practicality of the website for users searching for specific products.</li>
                </ul>
                <br>

                <div class="pListTitle">Optimized for Performance</div>
                <ul>
                  <li>Built a fast, responsive system capable of handling user requests efficiently without adding substantial operational costs.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[1] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  <div class="pListTitle">Dynamically Loading Current Data</div>
                  <ul>
                    <li>Set up AWS CloudWatch to trigger a weekly event that pulls updated product and store data from Google Sheets.</li>
                    <li>Processed the data and stored it in an optimized format in AWS DynamoDB.</li>
                    <li>Used Google Geocoding API to convert store addresses to latitude/longitude coordinates for proximity calculations.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Loading Filtered Data</div>
                  <ul>
                    <li>Users’ filtering actions triggered AWS Lambda functions to fetch relevant data from DynamoDB and return it to be displayed in Squarespace via API Gateway.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Handling Location Data</div>
                  <ul>
                    <li>User's location or entered zip code was matched with filtered products, approximating the 25 nearest stores.</li>
                    <li>Google Distance Matrix API was used to calculate the driving distance to each store, enhancing usability.</li>
                  </ul>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>AWS (Lambda, DynamoDB, API Gateway, IAM, CloudWatch)</li>
                    <li>Squarespace (JavaScript code injection)</li>
                    <li>JavaScript, HTML, CSS</li>
                    <li>Python (AWS Lambdas)</li>
                    <li>Google Geocoding API</li>
                    <li>Google Distance Matrix API</li>
                    <li>Google Sheets</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>            
            <div class="video1">              
              <video autoplay muted loop ref="video">
                <source src="../assets/Dabstract.mp4" title="Dabstract Drops Example" type="video/mp4" />
              </video>

            </div>
          </div>
        </div>
      </project-view>
    </div>
    <!-- ********** Rexroth Building and Interiors ********** -->
    <div style="height: 100%" ref="project3">
      <project-view
        projectName="Rexroth Building and Interiors"
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[2] = 'desc'"
              :class="[
                { navSelected: navActive[2] === 'desc' },
                { navUnderlined: navActive[2] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[2] = 'details'"
              :class="[
                { navSelected: navActive[2] === 'details' },
                { navUnderlined: navActive[2] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[2] = 'view'"
              :class="[
                { navSelected: navActive[2] === 'view' },
                { navUnderlined: navActive[2] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[2] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To develop a business website for Rexroth Building and Interiors, a small carpentry business, that showcases their craftsmanship and provides an online presence to attract potential clients.<br><br>
                  You can find the website here: <a href="https://rexroth-building.com" target="_blank">Rexroth Building and Interiors</a>
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>Create a modern, visually appealing website that reflects the quality and professionalism of their carpentry work.</li>
                    <li>Develop a gallery to display examples of completed projects.</li>
                    <li>Ensure the website is mobile-friendly and accessible across all devices.</li>
                    <li>Optimize the website for search engines to increase local visibility.</li>
                    <li>Provide a simple structure for easy navigation and user interaction.</li>
                    <li>Optimize website performance for faster load times.</li>
                  </ul>
                </div>
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Modern, Responsive Design</div>
                <ul>
                  <li>Created a user-friendly and visually appealing website accessible across all devices.</li>
                </ul>

                <div class="pListTitle">Gallery Showcase</div>
                <ul>
                  <li>Included a gallery to display high-quality images of completed carpentry projects, showcasing craftsmanship.</li>
                </ul>

                <div class="pListTitle">Optimized for SEO</div>
                <ul>
                  <li>Improved search engine visibility to help potential customers find the business more easily.</li>
                </ul>

                <div class="pListTitle">Fast Load Times</div>
                <ul>
                  <li>Ensured efficient performance with optimized page loading, enhancing user experience.</li>
                </ul>

                <div class="pListTitle">User-friendly Navigation</div>
                <ul>
                  <li>Simplified website structure for easy access to services, gallery, and contact information.</li>
                </ul>

                <div class="pListTitle">Visual Appeal</div>
                <ul>
                  <li>Incorporated clean, professional visuals to reflect the business’s quality and attention to detail.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[2] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  <div class="pListTitle">Firebase</div>
                  <ul>
                    <li>Used Firebase for hosting, ensuring reliable and scalable infrastructure.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Gallery Integration</div>
                  <ul>
                    <li>Incorporated a gallery to showcase images of finished carpentry projects, emphasizing craftsmanship and expertise.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Responsive Design</div>
                  <ul>
                    <li>Developed a mobile-friendly design, ensuring accessibility on all devices.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">SEO Optimization</div>
                  <ul>
                    <li>Improved SEO to enhance local search visibility and attract new clients.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Optimized Performance</div>
                  <ul>
                    <li>Optimized images and content for faster load times, ensuring a smooth and efficient user experience.</li>
                  </ul>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>Firebase</li>
                    <li>Vue.js</li>
                    <li>JavaScript</li>
                    <li>HTML/CSS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>
            <iframe
              src="https://rexroth-building.com"
              width="100%"
              height="100%"
              frameborder="0"
              title="Rexroth Building and Interiors Example"
            ></iframe>
          </div>
        </div>
      </project-view>
    </div>
    <!-- ********** Full Potential ********** -->
    <div style="height: 100%" ref="project4">
      <project-view
        projectName="Full Potential Inc."
        backgroundColor="rgb(100, 100, 100)"
      >
        <div class="pView">
          <div class="pNav">
            <div
              class="pBtn"
              @click="navActive[3] = 'desc'"
              :class="[
                { navSelected: navActive[3] === 'desc' },
                { navUnderlined: navActive[3] != 'desc' },
              ]"
            >
              Overview
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[3] = 'details'"
              :class="[
                { navSelected: navActive[3] === 'details' },
                { navUnderlined: navActive[3] != 'details' },
              ]"
            >
              Details
            </div>
            <div class="pNavSpace"></div>
            <div
              class="pBtn"
              @click="navActive[3] = 'view'"
              :class="[
                { navSelected: navActive[3] === 'view' },
                { navUnderlined: navActive[3] != 'view' },
              ]"
            >
              Example
            </div>
          </div>
          <div class="pBodyH" v-if="navActive[3] === 'desc'">
            <div class="pBody1">
              <div class="pLeft">
                <div class="pTitle">Description</div>
                <div class="pContent">
                  To develop a business website for Full-Potential, a company that empowers individuals with disabilities by offering a wide range of support services, resources, and tools to help them live more fulfilling and independent lives.<br><br>
                  You can find the website here: <a href="https://fullpotential-inc.org" target="_blank">Full Potential Inc.</a>
                </div>
                <br /><br /><br />
                <div class="pTitle">Requirements</div>
                <div class="pContent">
                  <ul>
                    <li>Create a visually welcoming and accessible website.</li>
                    <li>Provide a clear structure for users to easily navigate through services and resources.</li>
                    <li>Optimize the website for search engines to improve visibility.</li>
                    <li>Ensure fast load times for optimal user experience.</li>
                    <li>Incorporate a warm, professional design to reflect the company’s mission.</li>
                    <li>Highlight available resources and services for individuals with disabilities.</li>
                  </ul>
                </div>

                <!-- <p>
                  This project was to take an existing squarespace website and come up with a method of integrating more complex functionality within it. <br><br>
                  The goal was to update the website to display current products available at specific locations, and to allow the user to search for the closest location of a specific product.
                  If possible, the site was to be unchanged otherwise and it needed to be done in an effecient mannor.
                </p> -->
              </div>
              <div class="pRight">
                <div class="pTitle">Outcome</div>
                <div class="pListTitle">Resource and Support Section</div>
                <ul>
                  <li>Included a dedicated section to offer resources, support services, and helpful information for individuals with disabilities and their families.</li>
                </ul>

                <div class="pListTitle">Optimized for SEO</div>
                <ul>
                  <li>Enhanced search engine visibility to ensure people seeking disability support services can find the business more easily.</li>
                </ul>

                <div class="pListTitle">Fast Load Times</div>
                <ul>
                  <li>Optimized page performance to ensure fast load times for all users, regardless of internet connection.</li>
                </ul>

                <div class="pListTitle">User-friendly Navigation</div>
                <ul>
                  <li>Simplified navigation with clear labels and easy access to services, resources, and contact information.</li>
                </ul>

                <div class="pListTitle">Visual Appeal and Sensitivity</div>
                <ul>
                  <li>Incorporated warm, welcoming visuals that align with the company’s mission of helping individuals reach their full potential.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pBodyV" v-else-if="navActive[3] === 'details'">
            <div class="pBody2">
              <div class="pBlock1">
                <div class="pTitle">Solution</div>
                <div class="pContent">
                  <!-- Details > Solution -->
                  To develop a website from scratch using vue.js to meet clients
                  exact design requests<br /><br />
                  <div class="pListTitle">Firebase</div>
                  <ul>
                    <li>Used Firebase for hosting, ensuring reliable and scalable infrastructure.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Contact Form Integration</div>
                  <ul>
                    <li>Messages and contact info are emailed directly to the client from the website's contact form.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Responsive Design</div>
                  <ul>
                    <li>Created a mobile-friendly design, accessible on all devices, ensuring ease of use for all users.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">Optimized Performance</div>
                  <ul>
                    <li>Optimized images and content for fast load times, improving the overall user experience.</li>
                  </ul>
                  <br>

                  <div class="pListTitle">User-friendly Navigation</div>
                  <ul>
                    <li>Designed a simple and intuitive navigation structure for easy access to key services and resources.</li>
                  </ul>
                  <br>
                </div>
              </div>
              <div class="pBlock2">
                <div class="pTitle">Tech Stack</div>
                <div class="pContent">
                  <ul>
                    <li>Firebase (Firebase hosting)</li>
                    <li>Vue.js</li>
                    <li>JavaScript</li>
                    <li>HTML/CSS</li>
                    <li>EmailJS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="pBodyI" v-else>
            <iframe
              src="https://fullpotential-inc.org"
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </project-view>
    </div>    
    <div ref="contact" id="contact">
      <contact-view></contact-view>
    </div>
  </div>
  <!-- </div>
  <div v-else>
    <div class="mBackground">
      <div class="mTop">
        <div class="mLogoImg">
          <img src="../assets/logo2.png" class="mLogoImg1" />
        </div>
      </div>
      <div class="mBottom">
      </div>
    </div>
  </div> -->
  <div id="pageBottom"></div>
</template>
<script>
// import TheHeader from '../components/layouts/TheHeader.vue'
import HomeView from '../components/layouts/HomeView.vue'
import Reviews from '../components/layouts/Reviews.vue';
import AboutView from '../components/layouts/AboutView.vue'
import ContactView from '../components/layouts/ContactView.vue'
import ProjectsView from '../components/layouts/ProjectsView.vue'
import ProjectView from '../components/layouts/ProjectView.vue'
// import VideoPlayer from "../components/layouts/VideoJS.vue";
export default {
  components: { HomeView, Reviews, AboutView, ContactView, ProjectsView, ProjectView },
  name: 'mainview',
  data () {
    return {
      scrollPosition: null,
      showHeader: false,
      activePage: 'home',
      navActive: [],
      isMobile: false,
    }
  },  
  computed: {
    showReturnToTopButton () {
      return this.scrollPosition > 100
    },
    returnButtonClass () {
      console.log('Scroll P: ' +this.scrollPosition)
      console.log(this.getDivPos('reviews'));
      // Contact Form
      if(this.getDivPos('project4') + 25 < 0) {
        return 'greyView'
      }
      // Under Reviews
      else if (this.getDivPos('reviews') + 25 < 0) {
        return 'lightView'
      }
      //  else if(this.scrollPosition < this.getDivPosBottom('reviews') + this.getDivPosBottom('about') + 90) {
      //   return 'lightView'
      // }
      // Reviews
      else {
        return 'darkView'
      }
    },
    gotoVuex() {
      return this.$store.getters.goto;
    },    
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },
  methods: {
    projectNameHeight(name) {
    // while(!document.getElementById(name))
    // {
    //   setTimeout(() => {
        
    //   }, 100);
    // }
      // if(document.getElementById(name)){
      this.$nextTick(() => {
        let str = "calc(100% - " + document.getElementById(name).clientHeight + "px);";
        console.log(str);
        return str;
      });
      // } else {
      //   console.log('Failed');
      // }
    },
    getDivPos (name) {
      return this.$refs[name].getBoundingClientRect().top
    },    
    getDivPosBottom(name) {      
      return this.$refs[name].getBoundingClientRect().bottom;      
    },    
    updateScroll () {      
      const curPos = window.scrollY
      if (curPos < this.scrollPosition) {
        this.showHeader = true
      } else if (curPos > this.scrollPosition) {
        this.showHeader = false
      }
      this.scrollPosition = window.scrollY

      if (this.getDivPos('contact') < 5) {
        this.activePage = 'contact'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('contact')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      } else if (this.getDivPos('projects') < 5) {
        this.activePage = 'projects'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('projects')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      } else if (this.getDivPos('about') < 5) {
        this.activePage = 'about'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('about')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      }
      else if (this.getDivPos('reviews') < 5) {
        this.activePage = 'reviews'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('reviews')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      } else {
        this.activePage = 'home'
        if (
          Math.abs(
            document.documentElement.clientHeight - this.getDivPos('home')
          ) < 0.05
        ) {
          this.showHeader = false
        }
      }
      document.title = this.activePage.charAt(0).toUpperCase() + this.activePage.slice(1) + ' | Rexroth Development'

      if (this.$route.query.id != this.activePage) {
        this.$router.push({ query: { id: this.activePage }, params: { update: 'false' } })
      }      
    },
    windowSize () {      
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    goto (refName) {
      const element = this.$refs[refName]
      let top = element.offsetTop
      if (refName === 'home') {
        top = 0
      }
      window.scrollTo({
        left: 0,
        top: top,
        behavior: 'smooth'
      })
    }
  },
  mounted () {         
    window.addEventListener('scroll', this.updateScroll)
    window.addEventListener('resize', this.windowSize)
    this.windowSize()        
  },
  created () {
    for (let i = 0; i < 4; i++) {
      this.navActive.push('desc')
    }        
    // this.$nextTick(() => {
    //   console.log('Goto: ' + this.$route.query.id);
    //   this.goto(this.$route.query.id);
    // });
  },
  unmounted () {
    window.removeEventListener('scroll', this.updateScroll)
  }
}
</script>

<style scoped>
/* Project View */
ul {
  margin: 0;
}
/* .headerDivs {
  width:calc(100% - 8px);
} */
li {
  margin-bottom: 8px;
}
video {
  width:100%;
  height: 100%;
  max-height:100%;
  position:relative;
}
.pListTitle {
  font-weight: 600;
}
.pView {
  width: 100%;
  height: calc(100% - 75px);
  flex-grow: 1;
  background-color: rgb(127, 130, 135);
  position:relative;
}
.pNav {
  width: 100%;
  background-color: #9EA2AF;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
}
.pNavSpace {
  border: 1px solid rgb(53, 63, 79);
  height: 100%;
  box-sizing: border-box;
}
.pBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: black;
}
.pBtn:hover {
  background-color: rgb(127, 130, 135);
  cursor: pointer;
}
.navSelected {
  background-color: rgb(127, 130, 135);
}
.navUnderlined {
  border-bottom: 1px solid rgb(53, 63, 79);
}
.pBodyH {
  /* background-color:green; */
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
}
.pBody1 { 
  display: flex;
  justify-content: space-evenly;
  align-items: center; 
  /* margin: 5%; */
  height:100%;
}
.pBody2 { 
  width:80%;
  /* margin-left:10%; */
  /* margin: 5%; */
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; 
  margin: 5%; 
  height:100%; */
}
.pBodyV {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position:relative;
}
.pBodyI {
  width: 100%;
  height: calc(100% - 40px) !important;

  display: flex;
  justify-content: center;
  align-items: center;
  position:relative;
}

.pBodyI iframe {
  width: 90%;
  height: 90%;
  border: 5px solid white;
  box-sizing: border-box;
}
/* .vjs_tech {
  width:90%;
  height:90%;
  border: 5px solid white;
  box-sizing:border-box;
} */
/* #vjs_video_3, .vjs_tech {
  width: 90%;
  height:90%;
  border: 5px solid white;
  box-sizing:border-box;
} */
.pLeft,
.pRight {
  width: 40%;
  height: 80%;
  padding: 20px;
  background-color: white;
  overflow-y:auto;
}
.pLeft p {
  padding: 20px;
}

.pBlock1,
.pBlock2 {
  background-color: white;
  width: calc(100% - 40px);
  padding: 20px;
}
.pBlock1 {
  max-height: calc(v-bind('viewHeight') * .4);
  margin-bottom:5%;
  overflow-y: auto;
}
.pBlock2 {
  max-height: calc(v-bind('viewHeight') * .15);
  overflow-y: auto;
}
.project {
  /* position: relative;
  height: fit-content; */
}
.pTitle {
  font-size: 2em;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
/* .theHeader {
  position: fixed;
  top: 0;
  width: calc(100% - 16px);
  border-top: 8px solid white;
  box-sizing: border-box;
  z-index: 100;
} */
/* Back To Top Button */
.returnButton {
  position: fixed;
  bottom: calc(v-bind('viewHeight') * .02);
  right: calc(v-bind('viewHeight') * .03);
  text-decoration: underline;
  z-index:100;
}
.returnButton:hover {
  color: rgb(139, 139, 139);
  cursor: pointer;
}
.darkView {
  color: rgb(226, 226, 226);
}
.darkView:hover {
  color: rgb(194, 194, 194);
}
.lightView {
  color: rgb(66, 118, 186);
}
.lightView:hover {
  color: rgb(179, 179, 179);
}
.greyView {
  color: rgb(172, 172, 172);
}
.greyView:hover {

}

.video1 {
  width: 90%;
  height: 90% !important;
  /* height:calc(90% - 75px); */
  border: 5px solid white;
  box-sizing: border-box;
  text-align:center;
  background-color:black;
}


@media only screen and (max-width: 900px) {
  .pBodyH {
    border: 0px solid;
    box-sizing:border-box;
    padding:5%;    
    overflow-y: scroll;
    flex: 1 1 auto;    
    width: 100%;
  }
  .pBody1 {  
    display: block;
    overflow-y:auto;  
    /* overflow-y:hidden;   */
  }
  .pBodyV {
    display:block;
    border: 0px solid;
    box-sizing: border-box;
    padding:5%;
  }
  .pBody2 {
    width:100%;
    height:100%;
    overflow-y: auto;
  }
  .pRight, .pLeft {
    position:relative;
    overflow-y:hidden;
    width:inherit;
    height:fit-content;
  }
  
  .pRight {
    margin-top:5%;
  }
  .pBlock1,.pBlock2{
    /* padding:0; */
    height: fit-content;  
    overflow-y:hidden;
    max-height:none;
  }
  .pBodyI {
    height: calc(v-bind('viewHeight') * .6);
  }  
  .pView {    
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
</style>
