import {getAuth, signOut } from 'firebase/auth';
export default {
  setShowPP (state, payload) {
    state.showpp = payload.showpp
  },
  setCategory(state, payload) {
    state.category = payload.category;
  },
  setUser(state, payload) {
    state.user = payload.email
    localStorage.setItem('user', payload.email)
  },
  logOut(state) {
    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.removeItem('user')
      state.user = null;    
    })    
  },
  setGoto(state, payload) {
    state.goto = payload.goto;
  },
  clearGoto(state) {
    state.goto = '';
  },
  setViewHeight(state, payload) {
    state.viewHeight = payload.viewHeight;
  }
}
