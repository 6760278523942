import { createClient } from 'contentful';

// Standard Content Delivery API client
export const client = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
});

// Content Preview API client
export const previewClient = createClient({
  space: process.env.VUE_APP_CONTENTFUL_PREVIEW_SPACE_ID,
  accessToken: process.env.VUE_APP_CONTENTFUL_PREVIEW_TOKEN,
  host: 'preview.contentful.com', // Use the preview API endpoint
});

export default client;