<template>
    <div class="project" :style="newColor">
      <div :id="projectName" class="projectName" ref="projectName">{{ projectName }}</div>
      <slot></slot>
    </div>
</template>

<script>
export default {
  props: ['projectName', 'backgroundColor'],
  computed: {
    newColor () {
      return {
        backgroundColor: this.backgroundColor
      }
    },
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },

}
</script>

<style scoped>
.project {
  display:flex;
  /* position: unset; */
  flex-direction: column;  
  width: 100%;
  height: v-bind('viewHeight') !important;  
}
.projectName {
  width: 100%;
  border-top:5px solid white;
  box-sizing:border-box;
  text-align: center;
  font-size: 3em;
  padding:10px;
  /* position: relative;   */
}
@media only screen and (max-width: 900px) {
}
</style>
