import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import vClickOutside from 'click-outside-vue3'
import App from './App.vue'
import router from './router.js';
import store from './store/index.js';
import VueGtag from "vue-gtag";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const app = createApp(App);
// window.emitter = new vue();
const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if(user){
    store.commit('setUser', { email: user.email });    
  } 

})
app.use(createMetaManager())
app.use(router)
app.use(store)
app.use(vClickOutside)
app.use(VueGtag, {
  config: { id: "AW-16527861183" }
});

app.mount('#app')