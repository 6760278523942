export default {
  setShowPP (context, payload) {
    context.commit('setShowPP', payload);
  },
  setCategory(context, payload) {
    context.commit('setCategory', payload);
  },
  setUser(context, payload) {
    context.commit('setUser', payload);
  },
  logOut(context, payload) {
    context.commit('logOut', payload);
  },
  setGoto(context, payload) {
    context.commit('setGoto', payload);
  },
  setViewHeight(context, payload) {
    context.commit('setViewHeight', payload);
  }
}
