<template>
  <div class="d1">
    <h1>Reset Password</h1>
    <form class="f1" @submit.prevent="sendPwdReset">
      <label for="email">Enter the email associated with your account:</label>
      <input name="email" type="text" v-model="email" placeholder="Email" />    
      <button type="submit">Send password reset</button>
    </form>
  
    <div class="d2">
      <p class="p1">{{ message }}</p>
      <p class="p2">{{ errorMessage }}</p>
    </div>
    <div class="d3">
      <button @click="$router.push('/login')">Login</button>
      <button @click="$router.push('/signup')">Sign Up</button>
    </div>
    </div>
</template>

<script>
  import { getAuth, sendPasswordResetEmail } from "firebase/auth";

  export default {
    data() {
      return {
        email: '',
        message: '',
        errorMessage: '',
      }
    },
    methods: {
      async sendPwdReset() {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, this.email).then(() => {          
          this.message = 'Password reset email sent!';       
        }).catch((error) => {
          this.errorMessage = error.message;
        })
      }
    }
  }
</script>

<style scoped>
.d1 {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items:center;
  margin-top:20%;
}

.p1 {
  color: green;
}
.p2 {
  color: red;
}
.f1 {
  display: flex;
  flex-direction: column;
}
.f1 button{
  margin:5px;
}
</style>

